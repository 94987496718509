import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaSignOutAlt } from 'react-icons/fa';
import './CustomNavbar.css';

const handleLogout = () => {
  localStorage.removeItem('token');
  window.location.href = '/';
};

const token = localStorage.getItem('token') || sessionStorage.getItem('token');

const decodeJWT = (token) => {
  if (!token) {
    return null; // Token geçersizse null döndür
  }

  const base64Url = token.split('.')[1]; // Payload kısmını al
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload); // JSON objesi olarak dön
};

const decodedToken = decodeJWT(token);
const username = decodedToken ? decodedToken.username : 'Misafir'; // Eğer token geçersizse veya çözümlenemiyorsa 'Misafir' olarak ayarla

const CustomNavbar = () => {
  return (
    <Navbar bg="dark" variant="dark" className="Navbar">
      <Container className="myContainer">
        <Nav className="me-auto">
          <Nav.Link href="./admin-dashboard">Admin</Nav.Link>
          <Nav.Link href="./quotes-list">Teklifler</Nav.Link>
          <Nav.Link href="./create-quote">Teklif Oluştur</Nav.Link>
          <Nav.Link href="./profile">Profil</Nav.Link>
        </Nav>
        <div className="header">
          <h1>HİLALSAN</h1>
        </div>
        <div className="user-info">
          <p>{username}</p> {/* Kullanıcı adını burada gösteriyoruz */}
          <button className="icon-button" onClick={handleLogout}>
            <FaSignOutAlt size={20} />
          </button>
        </div>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
