import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar';
import Login from './components/Login';
import QuotesList from './components/QuotesList';
import CreateQuote from './components/CreateQuote';
import Profile from './components/Profile';
import AdminDashboard from './components/AdminDashboard';
import { AuthProvider, useAuth } from './components/AuthProvider';


function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavbar = location.pathname !== '/';

  return (
    <>
      {showNavbar && <CustomNavbar />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/quotes-list" element={<QuotesList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin-dashboard" element={<AdminProtectedRoute />} />
          <Route path="/create-quote" element={<CreateQuote />} /> {/* Yeni Route */}
        </Routes>
      </div>
    </>
  );
}

function AdminProtectedRoute() {
  const { user } = useAuth();
  return user?.role === 'admin' ? <AdminDashboard /> : <p>Access Denied</p>;
}

export default App;
