import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faFilter, faRecycle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Lightbox } from 'yet-another-react-lightbox';
import './QuotesList.css';

function QuotesList() {
    const [quotes, setQuotes] = useState([]);
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const [expandedQuoteNumber, setExpandedQuoteNumber] = useState(null);
    const [filePreviews, setFilePreviews] = useState({});
    const [open, setOpen] = useState(false);
    const [lightboxImageUrl, setLightboxImageUrl] = useState(null);
    const [filterType, setFilterType] = useState('companyName');
    const [filterValue, setFilterValue] = useState('');
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const today = new Date().toLocaleDateString('tr-TR').split('.').reverse().join('-');
    const [selectedDate, setSelectedDate] = useState(today);

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const quotesResponse = await fetch('/api/quotes/getall', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!quotesResponse.ok) {
                    throw new Error('Error fetching quotes');
                }

                const quotesData = await quotesResponse.json();
                setQuotes(quotesData);
            } catch (error) {
                console.error('Error fetching quotes:', error);
            }
        };
        if (token) {
            fetchQuotes();
        }
    }, [token]);

    useEffect(() => {
        filterQuotes(filterType, filterValue, today);
    }, [quotes]);

    useEffect(() => {
        filterQuotes(filterType, filterValue, selectedDate);
    }, [filterType, filterValue, selectedDate]);

    useEffect(() => {
        if (expandedQuoteNumber) {
            fetchFilePreviews(expandedQuoteNumber);
        }
    }, [expandedQuoteNumber]);

    const fetchFilePreviews = async (quoteNumber) => {
        try {
            const previews = [];
            let fileIndex = 0;
            const imageExtensions = ['png', 'jpg'];
            const pdfExtension = 'pdf';
            const seenFiles = new Set(); // Daha önce eklenen dosyaları takip etmek için bir Set

            while (fileIndex < 4) {
                let fileFound = false;

                for (const extension of imageExtensions) {
                    const fileName = `${quoteNumber}-${fileIndex}.${extension}`;
                    if (seenFiles.has(fileName)) continue; // Eğer dosya zaten önizleme listesine eklenmişse atla

                    const fileUrl = `https://hilalsan.diyotis.dev/api/quotes/upload/${fileName}`;
                    console.log(fileUrl);
                    const exists = await checkFileExists(fileUrl);
                    if (exists) {
                        fileFound = true;
                        previews.push({ fileName, fileUrl, type: 'image' });
                        seenFiles.add(fileName); // Dosya eklenmişse, Set'e ekle
                    }
                }

                const pdfFileName = `${quoteNumber}-${fileIndex}.${pdfExtension}`;
                if (!seenFiles.has(pdfFileName)) { // Eğer dosya önceden eklenmediyse
                    const pdfFileUrl = `https://hilalsan.diyotis.dev/api/quotes/upload/${pdfFileName}`;
                    const pdfExists = await checkFileExists(pdfFileUrl);
                    if (pdfExists) {
                        fileFound = true;
                        previews.push({ fileName: pdfFileName, fileUrl: pdfFileUrl, type: 'pdf' });
                        seenFiles.add(pdfFileName); // PDF dosyasını da Set'e ekle
                    }
                }

                if (!fileFound) {
                    break;
                }

                fileIndex++;
            }

            setFilePreviews(prevState => ({
                ...prevState,
                [quoteNumber]: previews
            }));
        } catch (error) {
            console.error('Error fetching file previews:', error);
        }
    };


    const checkFileExists = async (url) => {
        try {
            const response = await fetch(url, {
                method: 'HEAD',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.ok;
        } catch (error) {
            console.error('Error checking file:', error);
            return false;
        }
    };

    const handleEdit = () => {
        // Düzenleme işlemi için işlev
        console.log('Edit clicked');
    };

    const handleDelete = async (id, fileName) => {
        if (window.confirm('Bu teklifi silmek istediğinizden emin misiniz?')) {
            try {
                const response = await fetch(`https://hilalsan.diyotis.dev/api/quotes/remove/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Teklif silinirken bir hata oluştu');
                }
                let deleteCount = 0;
                while (deleteCount < 4) {
                    const res = await fetch(`https://hilalsan.diyotis.dev/api/quotes/upload/${fileName}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (!res.ok) {
                        deleteCount++;
                        if (deleteCount > 4) {
                            throw new Error('Fotoğraf silinirken bir hata oluştu');
                        }
                    }
                }
                // Teklif başarıyla silindi, teklif listesini yeniden yükleyin
                const updatedQuotesResponse = await fetch('/api/quotes/getall', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!updatedQuotesResponse.ok) {
                    throw new Error('Teklifler yeniden yüklenirken bir hata oluştu');
                }

                const updatedQuotesData = await updatedQuotesResponse.json();
                setQuotes(updatedQuotesData);
            } catch (error) {
                console.error('Hata:', error);
            }
        }
    };


    const handleFileClick = (fileUrl) => {
        setLightboxImageUrl(fileUrl);
        setOpen(true);
    };

    const handleDownload = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleExpand = (quoteNumber) => {
        setExpandedQuoteNumber(expandedQuoteNumber === quoteNumber ? null : quoteNumber);
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setSelectedDate(selectedDate);
        filterQuotes(filterType, filterValue, selectedDate);
    };

    const handleFilterTypeChange = () => {
        setFilterType(prevType => {
            switch (prevType) {
                case 'companyName':
                    return 'createdBy';
                case 'createdBy':
                    return 'city';
                default:
                    return 'companyName';
            }
        });
    };

    const handleFilterValueChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);
        filterQuotes(filterType, value, selectedDate);
    };

    const filterQuotes = (type, value, date) => {
        const formattedDate = date.slice(2).replace(/-/g, ''); // yyMMdd formatında tarih
        const filtered = quotes.filter(quote => {
            const quoteNumberStr = String(quote.quoteNumber); // quoteNumber'ı string'e dönüştürme
            const quoteDate = quoteNumberStr.slice(0, 6); // quoteNumber'dan yyMMdd formatındaki tarih
            if (formattedDate && quoteDate !== formattedDate) { // Eğer quoteNumber'daki tarih, selectedDate ile eşleşmiyorsa, bu teklifi dahil etme
                return false;
            }
            if (type === 'companyName' && !quote.companyName.toLowerCase().includes(value.toLowerCase())) {
                return false;
            }
            if (type === 'createdBy' && !quote.createdBy.toLowerCase().includes(value.toLowerCase())) {
                return false;
            }
            if (type === 'city' && !quote.city.toLowerCase().includes(value.toLowerCase())) {
                return false;
            }

            return true; // Tüm filtreler başarılıysa, teklifi listede tut
        });

        setFilteredQuotes(filtered);
    };

    return (
        <div className="quotes-container">
            <h2>Teklifler</h2>
            <div className="filter-container">
                <label htmlFor="date-filter">Tarih:</label>
                <input
                    type="date"
                    id="date-filter"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
                <button
                    className="filter-button"
                    onClick={handleFilterTypeChange}
                    title="Filtre Türünü Değiştir"
                >
                    <FontAwesomeIcon icon={faFilter} />
                </button>
                <label htmlFor="filter-value">{filterType === 'city' ? 'Şehir' : (filterType === 'companyName' ? 'Şirket Adı' : 'Teklif Veren')}:</label>
                <input
                    type="text"
                    id="filter-value"
                    value={filterValue}
                    onChange={handleFilterValueChange}
                />
            </div>
            <div className="quotes-list">
                {filteredQuotes.map((quote) => (
                    <div
                        key={quote.quoteNumber}
                        className={`quote-item ${expandedQuoteNumber === quote.quoteNumber ? 'expanded' : ''}`}
                    >
                        <div className="quote-header" onClick={() => handleExpand(quote.quoteNumber)}>
                            <span className="quote-number">
                                <span className="quote-label">Firma:</span> <span className="quote-value">{quote.companyName}</span>
                                <span className="company-name">Teklif No: {quote.quoteNumber}</span>
                                <span className="company-name">Teklif Veren: {quote.createdBy}</span>
                            </span>
                            <div>
                                <span className="expand-toggle">{expandedQuoteNumber === quote.quoteNumber ? '-' : '+'}</span>
                                <div className="edit-delete-buttons">
                                    <button className="edit-button" onClick={(e) => { e.stopPropagation(); handleEdit(); }}><FontAwesomeIcon icon={faEdit} /></button>
                                    <button className="delete-button" onClick={(e) => {
                                        e.stopPropagation();
                                        quote.quoteNumber.forEach(file => (quote.quoteNumber.forEach(file => handleDelete(quote._id, file.fileName),
                                            console.log(file.fileName))));
                                    }}><FontAwesomeIcon icon={faTrash} /></button>
                                </div>
                            </div>
                        </div>

                        {expandedQuoteNumber === quote.quoteNumber && (
                            <div className="quote-details">
                                <p><strong>Şirket:</strong> {quote.companyName}</p>
                                <p><strong>Yetkili Kişi:</strong> {quote.authorizedPerson}</p>
                                <p><strong>Vergi Dairesi:</strong> {quote.taxOffice}</p>
                                <p><strong>Vergi Numarası:</strong> {quote.taxNumber}</p>
                                <p><strong>Adres:</strong> {quote.address}</p>
                                <p><strong>Şehir:</strong> {quote.city}</p>
                                <p><strong>Telefon:</strong> {quote.phoneNumber}</p>
                                <p><strong>E-posta:</strong> {quote.email}</p>
                                <p><strong>Makine Kodu:</strong> {quote.machineCode}</p>
                                <p><strong>Teklif Tutarı:</strong> {quote.quotePrice.toFixed(2)} TL</p>
                                <p><strong>Açıklamalar:</strong> {quote.descriptions}</p>
                                <p><strong>Oluşturulma Tarihi:</strong> {quote.createdAt}</p>
                                <p><strong>Oluşturan:</strong> {quote.createdBy}</p>

                                {filePreviews[quote.quoteNumber] && filePreviews[quote.quoteNumber].map(file => (
                                    <div key={file.fileName} className="file-preview">
                                        {file.type === 'image' ? (
                                            <div>
                                                <img src={file.fileUrl} alt={file.fileName} className="file-preview-image"
                                                    onClick={() => handleFileClick(file.fileUrl)} />
                                            </div>
                                        ) : (
                                            <div>
                                                <button onClick={() => handleFileClick(file.fileUrl)}>
                                                    PDF Görüntüle
                                                </button>
                                            </div>
                                        )}
                                        <button className="file-download-button" onClick={() => handleDownload(file.fileUrl)}>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {open && lightboxImageUrl && (
                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={[{ src: lightboxImageUrl }]}
                />
            )}
        </div>
    );
}

export default QuotesList;
