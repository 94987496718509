import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider'; // AuthContext'ten useAuth hook'unu import ettik
import './Profile.css'; // Stil dosyasını ekledik

function Profile() {
  const { user, logout } = useAuth();
  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    currentPassword: '',
    newPassword: ''
  });
  const [originalProfile, setOriginalProfile] = useState({});

  // Kullanıcı bilgilerini al
  useEffect(() => {
    
    if (user) {
      console.log(user);
      setProfile({
        email: user.email || '',
        phone: user.phone || '',
        currentPassword: '', // Mevcut şifre alanı boş bırakılır
        newPassword: '' // Yeni şifre alanı da boş bırakılır
      });
      setOriginalProfile(user);
    }
  }, [user]);

  // Kullanıcı profilini güncelle
  const handleUpdateProfile = async () => {
    try {
      const requestBody = {
        email: profile.email,
        phone: profile.phone
      };

      // Eğer yeni şifre girildiyse, bunu requestBody'e ekle
      if (profile.currentPassword && profile.newPassword) {
        requestBody.currentPassword = profile.currentPassword;
        requestBody.newPassword = profile.newPassword;
      }

      const response = await fetch(`/api/auth/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) throw new Error('Profil güncellenemedi');
      alert('Profil başarıyla güncellendi');
    } catch (error) {
      console.error('Hata:', error);
      alert('Profil güncellenirken bir hata oluştu.');
    }
  };

  return (
    <div className="profile">
      <h2>Profil Bilgileri</h2>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleUpdateProfile();
      }}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={profile.email}
            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Telefon:</label>
          <input
            type="text"
            value={profile.phone}
            onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Mevcut Şifre:</label>
          <input
            type="password"
            value={profile.currentPassword}
            onChange={(e) => setProfile({ ...profile, currentPassword: e.target.value })}
            placeholder="Mevcut şifrenizi girin"
          />
        </div>
        <div className="form-group">
          <label>Yeni Şifre:</label>
          <input
            type="password"
            value={profile.newPassword}
            onChange={(e) => setProfile({ ...profile, newPassword: e.target.value })}
            placeholder="Yeni şifrenizi girin (Değiştirmek istemiyorsanız boş bırakın)"
          />
        </div>
        <button type="submit">Güncelle</button>
      </form>
      <button onClick={logout}>Çıkış Yap</button>
    </div>
  );
}

export default Profile;
