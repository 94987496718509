import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker'; // Tarih seçici
import 'react-datepicker/dist/react-datepicker.css'; // Tarih seçici stilleri
import './CreateQuote.css';
import { useAuth } from './AuthProvider';

function CreateQuote() {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');
    const [authorizedPerson, setAuthorizedPerson] = useState('');
    const [taxOffice, setTaxOffice] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [machineCode, setMachineCode] = useState('');
    const [quotePrice, setQuotePrice] = useState('');
    const [descriptions, setDescriptions] = useState('');
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [createdAt, setCreatedAt] = useState(new Date()); // Tarih nesnesi
    const [createdBy, setCreatedBy] = useState('');
    const [id, setId] = useState('');
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            setCreatedBy(user['username']); // Kullanıcı adını set ediyoruz
        } else {
            console.log("Kullanıcı bulunamadı");
        }
    }, [user]);

    const handleFileChange = (event) => {
        setFiles([...event.target.files]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (!token) {
            setMessage('Lütfen giriş yapın.');
            return;
        }

        const quoteData = {
            companyName,
            authorizedPerson,
            taxOffice,
            taxNumber,
            address,
            city,
            phoneNumber,
            email,
            machineCode,
            quotePrice,
            descriptions,
            createdAt: createdAt.toISOString(), // Tarih nesnesini ISO stringe çeviriyoruz
            createdBy,
        };

        try {
            const response = await fetch('/api/quotes/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(quoteData)
            });

            const contentType = response.headers.get("content-type");

            if (response.ok) {

                const data = await response.json();
                setId(data._id);
                console.log('Teklif başarıyla oluşturuldu! ', data._id, 'setid ', id);

                if (files.length > 0 && data._id) {
                    const formData = new FormData();
                    files.forEach(file => formData.append('files', file));
                    await uploadFiles(formData, data._id);
                }
                else {
                    alert('id boş');
                }

                setCompanyName('');
                setAuthorizedPerson('');
                setTaxOffice('');
                setTaxNumber('');
                setAddress('');
                setCity('');
                setPhoneNumber('');
                setEmail('');
                setMachineCode('');
                setQuotePrice('');
                setDescriptions('');
                setFiles([]);
                setCreatedAt(new Date()); // Tarihi sıfırlıyoruz
                navigate('/quotes-list');
            } else {
                const errorMessage = contentType && contentType.includes("application/json")
                    ? await response.json()
                    : await response.text();
                console.error("Hata:", errorMessage);
                setMessage(`Hata: ${errorMessage}`);
            }
        } catch (error) {
            setMessage('Teklif oluşturulurken bir hata oluştu.');
            console.error('Teklif oluşturulurken hata oluştu:', error);
        }
    };

    const uploadFiles = async (formData, id) => {
        try {
            const response = await fetch(`/api/quotes/upload/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Hata: ${errorData.message}`);
            }
        } catch (error) {
            setMessage(`Dosya yüklenirken bir hata oluştu: ${error.message}`);
            console.error('Error uploading files:', error);
        }
    };
    return (
        <div className="create-quote-container">
            <h2>Yeni Teklif Oluştur</h2>
            {message && <p className="message">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Oluşturulma Tarihi</label>
                    <DatePicker
                        selected={createdAt}
                        onChange={(date) => setCreatedAt(date)}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Tarih seçin"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Şirket Adı</label>
                    <input
                        placeholder='Şirketin Tam Adı'
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Yetkili Kişi</label>
                    <input
                        placeholder='Yetkili Kişi Adı'
                        type="text"
                        value={authorizedPerson}
                        onChange={(e) => setAuthorizedPerson(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Vergi Dairesi</label>
                    <input
                        placeholder='Vergi Dairesi'
                        type="text"
                        value={taxOffice}
                        onChange={(e) => setTaxOffice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Vergi Numarası</label>
                    <input
                        placeholder='Vergi Numarası'
                        type="text"
                        value={taxNumber}
                        onChange={(e) => setTaxNumber(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Adres</label>
                    <input
                        placeholder='Şirket Adresi'
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Şehir</label>
                    <input
                        placeholder='Şehir'
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Telefon Numarası</label>
                    <input
                        placeholder='Telefon Numarası'
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        placeholder='Email Adresi'
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Makine Kodu</label>
                    <input
                        type="text"
                        placeholder='Makine Liste Kodu'
                        value={machineCode}
                        onChange={(e) => setMachineCode(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Teklif Miktarı</label>
                    <input
                        type="text"
                        placeholder='15000.00'
                        value={quotePrice}
                        onChange={(e) => setQuotePrice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Açıklamalar</label>
                    <textarea
                        value={descriptions}
                        onChange={(e) => setDescriptions(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Dosya Yükle:</label>
                    <input
                        type="file"
                        multiple
                        accept=".png, .jpg, .jpeg, .pdf"
                        onChange={handleFileChange}
                        className="add-quote-file-input"
                    />
                </div>

                <input type="hidden" value={createdBy} />
                <button type="submit">Teklif Oluştur</button>
            </form>
        </div>
    );
}

export default CreateQuote;
