import React, { useState } from 'react'; 
import logo from '../assets/logo.png';
import './Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Giriş başarısız. Lütfen kullanıcı adı ve şifrenizi kontrol edin.');
      }

      const data = await response.json();
      const token = data.token;

      // Token'ı localStorage'a kaydedin
      localStorage.setItem('token', token);
      console.log(token);
      // Başarılı giriş sonrası yönlendirme
      window.location.href = '/quotes-list'; // Bu kısım istenilen sayfaya yönlendirilebilir.
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page login-page-base">
      <div className="login-page-base-wrapper">
      <img src={logo} alt="Logo" className="login-logo" />
        <h2 className="login-title">Giriş Yap</h2>
        <form className="login-form" onSubmit={handleLogin}>
          <div className="input-group">
            <label>Kullanıcı Adı:</label>
            <input
              type="text"
              value={username.toLowerCase().trim()}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <div className="input-group">
            <label>Şifre:</label>
            <input
              type="password"
              value={password.trim()}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-button">Giriş Yap</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
